<template>
  <div class="transaction-categories">
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Danh sách danh mục thu chi'">
          <template v-slot:toolbar>
            <div class="row">
              <b-button
                v-show="checkPermission('TRANSACTION_CATE_INSERT')"
                variant="primary"
                size="sm"
                @click="showModalAddCate"
                class="btn btn-primary font-weight-bolder btn-sm"
              >
                <i style="font-size: 1rem" class="flaticon2-add-1"></i>Tạo mới
              </b-button>
            </div>
          </template>
          <template v-slot:preview>
            <div class="col-md-12 mb-5">
              <b-form>
                <div class="row">
                  <div class="col-md-8">
                    <div class="row">
                      <b-input
                        placeholder="Tìm kiếm theo tên"
                        v-model="searchName"
                        append-icon="search"
                        single-line
                        hide-details
                        class="col-md-4 mr-4"
                        @keyup="onSearch"
                        v-on:keyup.enter="fetchData"
                        size="sm"
                      ></b-input>
                      <b-input
                        placeholder="Tìm kiếm theo mã"
                        v-model="searchCode"
                        append-icon="search"
                        single-line
                        hide-details
                        class="col-md-4"
                        @keyup="onSearch"
                        v-on:keyup.enter="fetchData"
                        size="sm"
                      ></b-input>
                    </div>
                  </div>
                </div>
              </b-form>
            </div>
            <b-modal ref="add-cate-modal" hide-footer title="Thêm mới danh mục">
              <v-form ref="form" lazy-validation>
                <div class="row">
                  <b-col md="6">
                    <b-form-group
                      id="input-group-2"
                      label-for="input-2"
                      aria-describedby="input-2-live-feedback"
                    >
                      <template>
                        <span>Tên:</span>
                        <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="input-2"
                        type="name"
                        v-model="name"
                        required
                        placeholder="Nhập tên"
                        size="sm"
                        :state="validateState('name')"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        v-if="!$v.name.required"
                        id="input-2-live-feedback"
                        >Yêu cầu nhập tên danh mục</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      id="input-group-3"
                      label-for="input-3"
                      aria-describedby="input-2-live-feedback"
                    >
                      <template>
                        <span>Mã:</span>
                        <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="input-3"
                        type="name"
                        v-model="code"
                        required
                        placeholder="Nhập mã"
                        size="sm"
                        :state="validateState('code')"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        v-if="!$v.code.required"
                        id="input-3-live-feedback"
                        >Yêu cầu nhập mã danh mục</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>
                </div>
                <div class="row">
                  <b-col md="6">
                    <b-form-group
                      id="input-group-3"
                      label="Danh mục cha:"
                      label-for="input-3"
                    >
                      <b-form-select
                        id="input-1"
                        v-model="selectedParent"
                        :options="listCate"
                        required
                        size="sm"
                        value-field="id"
                        text-field="name"
                        disabled-field="notEnabled"
                        class="select-style"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                            >-- Chọn danh mục cha --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      id="input-group-3"
                      label="Loại:"
                      label-for="input-3"
                    >
                      <b-form-select
                        id="input-1"
                        v-model="selectedType"
                        :options="listType"
                        required
                        size="sm"
                        value-field="id"
                        text-field="name"
                        disabled-field="notEnabled"
                        class="select-style"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                            >-- Chọn loại danh mục --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </div>

                <div class="row">
                  <b-col md="6">
                    <b-form-group
                      id="input-group-3"
                      label="Đối tượng:"
                      label-for="input-3"
                    >
                      <b-form-select
                        id="input-1"
                        v-model="selectedContact"
                        :options="listContact"
                        required
                        size="sm"
                        value-field="id"
                        text-field="name"
                        disabled-field="notEnabled"
                        class="select-style"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                            >-- Chọn loại đối tượng --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group id="input-group-1">
                      <label for="input-transfer-account"
                        >Tài khoản mặc định:</label
                      >
                      <Autosuggest
                        :model="selectedDefaultAcc"
                        :suggestions="filteredCashAccOptions"
                        placeholder="tài khoản mặc định"
                        :limit="10"
                        @select="onCashAccSelected"
                        @change="onInputCashAccChange"
                        suggestionName="suggestionName"
                      />
                    </b-form-group>
                  </b-col>
                </div>

                <b-button
                  style="fontweight: 600; width: 70px; margin-left: 10px;"
                  variant="primary"
                  size="sm"
                  v-show="checkPermission('TRANSACTION_CATE_INSERT')"
                  @click="createCategory"
                  >Lưu</b-button
                >
                <b-button
                  style="margin-right: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="hideModalAddCate"
                  >Hủy</b-button
                >
              </v-form>
            </b-modal>
            <b-modal
              ref="update-cate-modal"
              hide-footer
              title="Cập nhật danh mục"
            >
              <v-form ref="form" lazy-validation>
                <div class="row">
                  <b-col md="6">
                    <b-form-group
                      id="input-group-2"
                      label-for="input-2"
                      aria-describedby="input-2-live-feedback"
                    >
                      <template>
                        <span>Tên:</span>
                        <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="input-2"
                        type="text"
                        v-model="name"
                        required
                        placeholder="Nhập tên"
                        size="sm"
                        :state="validateState('name')"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        v-if="!$v.name.required"
                        id="input-2-live-feedback"
                        >Yêu cầu nhập tên danh mục</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      id="input-group-3"
                      label-for="input-3"
                      aria-describedby="input-2-live-feedback"
                    >
                      <template>
                        <span>Mã:</span>
                        <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="input-3"
                        type="text"
                        v-model="code"
                        required
                        placeholder="Nhập mã"
                        size="sm"
                        :state="validateState('code')"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        v-if="!$v.code.required"
                        id="input-3-live-feedback"
                        >Yêu cầu nhập mã danh mục</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>
                </div>
                <div class="row">
                  <b-col md="6">
                    <b-form-group
                      id="input-group-3"
                      label="Danh mục cha:"
                      label-for="input-3"
                    >
                      <b-form-select
                        id="input-1"
                        v-model="selectedParent"
                        :options="listCate"
                        required
                        size="sm"
                        value-field="id"
                        text-field="name"
                        disabled-field="notEnabled"
                        class="select-style"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                            >-- Chọn danh mục cha --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      id="input-group-3"
                      label="Loại:"
                      label-for="input-3"
                    >
                      <b-form-select
                        id="input-1"
                        v-model="selectedType"
                        :options="listType"
                        required
                        size="sm"
                        value-field="id"
                        text-field="name"
                        disabled-field="notEnabled"
                        class="select-style"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                            >-- Chọn loại danh mục --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </div>

                <div class="row">
                  <b-col md="6">
                    <b-form-group
                      id="input-group-3"
                      label="Đối tượng:"
                      label-for="input-3"
                    >
                      <b-form-select
                        id="input-1"
                        v-model="selectedContact"
                        :options="listContact"
                        required
                        size="sm"
                        value-field="id"
                        text-field="name"
                        disabled-field="notEnabled"
                        class="select-style"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                            >-- Chọn loại đối tượng --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group id="input-group-1">
                      <label for="input-transfer-account"
                        >Tài khoản mặc định:</label
                      >
                      <Autosuggest
                        :model="selectedDefaultAcc"
                        :suggestions="filteredCashAccOptions"
                        placeholder="tài khoản mặc định"
                        :limit="10"
                        @select="onCashAccSelected"
                        @change="onInputCashAccChange"
                        suggestionName="suggestionName"
                      />
                    </b-form-group>
                  </b-col>
                </div>

                <b-button
                  style="fontweight: 600; width: 70px; margin-left: 10px;"
                  variant="primary"
                  size="sm"
                  @click="updateCategory"
                  v-show="checkPermission('TRANSACTION_CATE_UPDATE')"
                  >Lưu</b-button
                >
                <b-button
                  style="margin-right: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="hideModalUpdateCate"
                  >Hủy</b-button
                >
              </v-form>
            </b-modal>
            <b-table
              :items="listItem"
              :fields="fields"
              class="table-bordered table-hover col-md-12"
              :busy="onLoading"
            >
              <template v-slot:table-busy>
                <vcl-table :speed="5" :animate="true" :columns="4"></vcl-table>
              </template>
              <template v-slot:cell(stt)="row">
                <div class="d-flex justify-content-center w-6">
                  <span v-text="row.item.stt"></span>
                </div>
              </template>
              <template v-slot:cell(name)="row">
                <div v-if="row.item.level == 1">
                  <p class="ml-10">{{ row.item.name }}</p>
                </div>
                <div v-else-if="row.item.level == 2">
                  <p class="ml-20">{{ row.item.name }}</p>
                </div>
                <div v-else-if="row.item.level == 3">
                  <p class="ml-30">{{ row.item.name }}</p>
                </div>
                <div v-else-if="row.item.level == 4">
                  <p class="ml-40">{{ row.item.name }}</p>
                </div>
                <div v-else-if="row.item.level == 5">
                  <p class="ml-50">{{ row.item.name }}</p>
                </div>
                <div v-else-if="row.item.level == 0">
                  <p>{{ row.item.name }}</p>
                </div>
              </template>

              <template v-slot:cell(code)="row">
                <div v-if="row.item.level == 1">
                  <p class="ml-10">{{ row.item.code }}</p>
                </div>
                <div v-else-if="row.item.level == 2">
                  <p class="ml-20">{{ row.item.code }}</p>
                </div>
                <div v-else-if="row.item.level == 3">
                  <p class="ml-30">{{ row.item.code }}</p>
                </div>
                <div v-else-if="row.item.level == 4">
                  <p class="ml-40">{{ row.item.code }}</p>
                </div>
                <div v-else-if="row.item.level == 5">
                  <p class="ml-50">{{ row.item.name }}</p>
                </div>
                <div v-else-if="row.item.level == 0">
                  <p>{{ row.item.code }}</p>
                </div>
              </template>
              <template v-slot:cell(status)="row">
                <i
                  v-bind:class="{
                    'fas fa-check text-success': row.item.status == 1,
                    'fas fa-minus-circle text-danger': row.item.status != 1,
                  }"
                />
              </template>
              <template v-slot:cell(type)="row">
                <span
                  v-if="row.item.type === 1"
                  v-text="row.item.typeName"
                  class="label font-weight-bold label-lg label-light-success label-inline"
                ></span>
                <span
                  v-else
                  v-text="row.item.typeName"
                  class="label font-weight-bold label-lg label-light-danger label-inline"
                ></span>
              </template>
              <template v-slot:cell(actions)="row">
                <div class="d-flex justify-content-center">
                  <b-dropdown size="sm" id="dropdown-left" no-caret right>
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem; padding-right: 0px"
                        class="flaticon2-settings"
                      ></i>
                    </template>
                    <b-dropdown-item
                      @click="editItem(row.item)"
                      v-if="checkViewOnly()"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 10px" class="flaticon2-box-1"></i>
                        &nbsp; Chi tiết
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="editItem(row.item)"
                      v-if="!checkViewOnly()"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 1rem" class="flaticon2-pen"></i>
                        &nbsp; Chỉnh sửa
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="addChildCate(row.item)"
                      v-show="checkPermission('TRANSACTION_CATE_INSERT')"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem; color: #1bc5bc !important"
                          class="fas fa-plus"
                        ></i>
                        &nbsp; Thêm danh mục con
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="showDeleteAlert(row.item)"
                      v-show="checkDelete(row.item)"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem; color: #d33"
                          class="flaticon2-rubbish-bin-delete-button"
                        ></i>
                        &nbsp; Xóa
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <b-row>
              <b-col>
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số danh mục:
                  {{ totalRow }}
                </p>
              </b-col>
              <b-col style="margin-right: 400px" v-if="false">
                <b-pagination-nav
                  v-show="numberOfPage >= 2"
                  class="customPagination"
                  :link-gen="linkGen"
                  :number-of-pages="numberOfPage"
                  use-router
                  @change="fetchData"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                  prev-class="page-item-prev btn btn-icon btn-sm mr-1 my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1"
                  last-class="page-item-last btn btn-icon btn-sm my-1"
                  page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                ></b-pagination-nav>
              </b-col>
            </b-row>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import localData from '@/utils/saveDataToLocal';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import { validationMixin } from 'vuelidate';
import { VclTable } from 'vue-content-loading';
import { required } from 'vuelidate/lib/validators';
import { CONTACT_TYPE_CATE } from '@/utils/constants';
import { removeAccents } from '@/utils/common';
import { getToastInstance } from '@/utils/toastHelper';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

export default {
  mixins: [validationMixin],
  data() {
    return {
      searchName: '',
      searchCode: '',
      fields: [
        {
          key: 'stt',
          label: 'STT',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
        },
        {
          key: 'name',
          label: 'Tên',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '40%' },
        },
        {
          key: 'code',
          label: 'Mã',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '40%' },
        },
        {
          key: 'type',
          label: 'Loại',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { width: '10%' },
          class: 'text-center',
        },
        { key: 'actions', label: '' },
      ],
      listItem: [
        {
          stt: 1,
          name: 'Gói bảo hành',
          code: 'GOIBAOHANH',
          id: 3012,
          numOfProduct: '200',
          parent_id: null,
          level: 0,
        },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      numberOfPage: null,
      count: 1,
      listCate: [],
      selectedParent: null,
      name: '',
      code: '',
      valid: true,
      cateId: 0,
      totalRow: 0,
      onLoading: false,
      submitted: false,
      isNew: true,
      listType: [
        {
          id: 1,
          name: 'Thu',
        },
        {
          id: 2,
          name: 'Chi',
        },
      ],
      selectedType: null,
      listContact: CONTACT_TYPE_CATE,
      selectedContact: null,
      selectedDefaultAcc: '',
      selectedDefaultAccId: null,
      filteredCashAccOptions: [],
      cashAccOptions: [
        {
          data: [],
        },
      ],
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    Autosuggest
  },
  validations: {
    name: {
      required,
    },
    code: {
      required,
    },
  },
  created() {
    this.getListCate();
    this.fetchData();
    this.fetchAccountant();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Danh mục thu chi', route: '/transaction-categories' },
      { title: 'Danh sách danh mục thu chi' },
    ]);
  },
  methods: {
    ...getToastInstance(),	
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v[name];
        return $dirty ? !$error : null;
      }
    },
    isValidData(data) {
      var format = /[`!@#$%^&*()+\-={};':"|,.<>?~]/;
      return !format.test(data);
    },
    getListCate: function () {
      this.listCate = [];
      let param = {
        name: '',
        code: '',
      };
      let paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('transactionCategory', paramQuery).then(({ data }) => {
        this.totalRow = data.data.total_row;
        this.numberOfPage = data.data.total_page;
        data.data.list_caterogy.forEach((element) => {
          let name = '';
          if (element.level === 1) {
            name = '- ' + element.name;
          } else if (element.level === 2) {
            name = '- - ' + element.name;
          } else if (element.level === 3) {
            name = '- - - ' + element.name;
          } else if (element.level === 4) {
            name = '- - - - ' + element.name;
          } else if (element.level === 5) {
            name = '- - - - - ' + element.name;
          } else if (element.level === 6) {
            name = '- - - - - - ' + element.name;
          } else {
            name = element.name;
          }
          let item = {
            id: element.id,
            name: name,
          };
          this.listCate.push(item);
        });
      });
    },
    onSearch() {
      this.fetchData();
    },
    fetchData: async function () {
      let param = {
        name: this.searchName,
        code: this.searchCode,
      };
      let paramQuery = {
        params: param,
      };
      this.onLoading = true;
      ApiService.setHeader();
      ApiService.query('transactionCategory', paramQuery)
        .then(({ data }) => {
          this.count = 1;
          this.totalRow = data.data.total_row;
          this.numberOfPage = data.data.total_page;
          this.listItem = [];
          data.data.list_caterogy.forEach((element) => {
            let item = {
              id: element.id,
              stt: this.count,
              name: element.name,
              code: element.code,
              level: element.level,
              type: element.type,
              status: element.status,
              typeName: element.typeName,
            };
            this.listItem.push(item);
            this.count++;
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = false;
        });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    checkViewOnly: function () {
      let count = 0;
      if (localData.checkPermission('TRANSACTION_CATE_VIEW')) {
        count++;
      }
      if (localData.checkPermission('TRANSACTION_CATE_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
    deleteItem: async function (item) {
      let id = item.id;
      ApiService.setHeader();
      ApiService.post(`transactionCategory/delete/${id}`).then(({ data }) => {
        if (data.status === 1) {
          this.makeToastSuccess(data.message);
          this.fetchData();
        } else {
          this.makeToastFailure(data.message);
        }
      });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa danh mục!',
        text: 'Bạn có chắc muốn xóa danh mục này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    checkDelete: function (item) {
      if ([1, 2].includes(item.id)) {
        return false;
      } else {
        return this.checkPermission('TRANSACTION_CATE_DELETE');
      }
    },
    editItem: function (item) {
      this.cateId = item.id;
      this.getCategoryById();
      this.showModalUpdateCate();
    },
    getCategoryById: function () {
      ApiService.setHeader();
      ApiService.get(`transactionCategory/${this.cateId}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.name = data.data.name;
            this.code = data.data.code;
            this.selectedParent = data.data.parentId;
            this.selectedType = data.data.type;
            this.selectedContact = data.data.contactId;
            this.selectedDefaultAccId = data.data.defaultAccountId;
            this.selectedDefaultAcc = data.data.defaultAccountName;
          } else {
            this.makeToastFailure('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          this.makeToastFailure(response.$error);
        });
    },
    addChildCate: function (item) {
      this.showModalAddCate();
      this.selectedParent = item.id;
    },
    showModalAddCate() {
      this.isNew = true;
      this.selectedParent = null;
      this.name = '';
      this.code = '';
      this.selectedType = null;
      this.selectedContact = null;
      this.selectedDefaultAcc = '';
      this.selectedDefaultAccId = null;
      this.$refs['add-cate-modal'].show();
    },
    hideModalAddCate() {
      this.$refs['add-cate-modal'].hide();
    },
    showModalUpdateCate() {
      this.isNew = true;
      this.$refs['update-cate-modal'].show();
    },
    hideModalUpdateCate() {
      this.$refs['update-cate-modal'].hide();
    },
    createCategory: function () {
      this.isNew = false;
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      if (!this.isValidData(this.name)) {
        alert('Tên danh mục không được chứa ký tự đặc biệt!');
        return;
      }
      if (!this.isValidData(this.code)) {
        alert('Mã danh mục không được chứa ký tự đặc biệt!');
        return;
      }
      const name = this.name;
      const code = this.code;
      let data = {
        code: code,
        name: name,
        parentId: this.selectedParent,
        type: this.selectedType,
        defaultAccountId: this.selectedDefaultAccId,
        contactId: this.selectedContact,
      };
      ApiService.setHeader();
      ApiService.post('transactionCategory/create', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.fetchData();
            this.getListCate();
            this.hideModalAddCate();
          } else {
            this.makeToastFailure(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFailure(response.$error);
        });
    },
    updateCategory: async function () {
      this.isNew = false;
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      if (!this.isValidData(this.name)) {
        alert('Tên danh mục không được chứa ký tự đặc biệt!');
        return;
      }
      if (!this.isValidData(this.code)) {
        alert('Mã danh mục không được chứa ký tự đặc biệt!');
        return;
      }
      const name = this.name;
      const code = this.code;
      let data = {
        id: this.cateId,
        name: name,
        code: code,
        parentId: this.selectedParent,
        type: this.selectedType,
        defaultAccountId: this.selectedDefaultAccId,
        contactId: this.selectedContact,
      };
      ApiService.setHeader();
      ApiService.post('transactionCategory/update', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.fetchData();
            this.getListCate();
            this.hideModalUpdateCate();
          } else {
            this.makeToastFailure(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFailure(response.$error);
        });
    },
    onCashAccSelected(option) {
      this.selectedDefaultAcc = option.item.name;
      this.selectedDefaultAccId = option.item.code;
    },
    onInputCashAccChange(text) {
      this.selectedDefaultAcc = text;
      const filteredData = this.cashAccOptions[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1 ||
            removeAccents(item.code)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCashAccOptions = [...filteredData];
    },
    fetchAccountant: async function () {
      ApiService.setHeader();
      let params = {
        page: 1,
        pageSize: 10000,
        search: '',
        searchCode: '',
      };
      ApiService.query('accountants', { params }).then((response) => {
        this.cashAccOptions[0].data = [];
        response.data.data.list_accountant.forEach((item) => {
          let prefix = '';
          for (let index = 0; index < item.level; index++) {
            prefix += '- ';
          }
          let acc = {
            id: item.id,
            name: item.name,
            code: item.code,
            suggestionName: prefix + item.code,
          };
          this.cashAccOptions[0].data.push(acc);
        });
        this.filteredCashAccOptions = [...this.cashAccOptions[0].data];
      });
    },
  },
};
</script>

<style lang="scss">
.transaction-categories {
  .icon {
    padding-top: 7px;
  }
  .ml-10 {
    margin-left: 5px;
  }
  .ml-20 {
    margin-left: 10px;
  }
  .ml-30 {
    margin-left: 15px;
  }
  .ml-40 {
    margin-left: 20px;
  }
  .ml-50 {
    margin-left: 25px;
  }

  .sttCateClass {
    width: 5%;
  }
  .nameCateClass {
    width: 45%;
  }
  .codeCateClass {
    width: 45%;
  }
  .icon:hover {
    background-color: #90c6fc;
  }

  .container-fluid {
    height: 100%;
    display: table;
    width: 100%;
    padding: 0;
  }

  .row-fluid {
    height: 100%;
    display: table-cell;
    vertical-align: middle;
  }

  .centering {
    float: none;
    margin: 0 auto;
  }
}
</style>